const jQuery = require('jquery')
const waypoints = require('jquery-waypoints/waypoints.min')

exports.onRouteUpdate = () => {
  window.jQuery = jQuery
  window.waypoints = waypoints

  if (window.themeScripts) {
    window.themeScripts()
  }
}
